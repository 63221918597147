<template>
	<div class="">
		<div class="lg:container lg:mx-auto w-full">
			<div class="lg:h-screen flex items-center justify-center">
				<div class="bg-white w-full h-screen lg:rounded-2xl lg:h-auto pt-8 lg:pt-auto lg:py-16 px-4 lg:px-0">
					<NuxtLink class="mx-auto lg:hidden w-fit flex" to="/">
						<img src="/images/logo-black.svg" alt="StreamersClub" />
					</NuxtLink>
					<div class="lg:max-w-4xl lg:flex lg:mx-auto lg:gap-16">
						<NuxtLink class="hidden lg:block" to="/">
							<img src="/images/auth-banner.jpg" alt="streamers club banner" />
						</NuxtLink>
						<div class="space-y-6 flex-1 my-auto" v-if="!isEmailSent">
							<Button
								:href="googleRedirectUrl"
								variant="outline"
								class="text-base flex items-center justify-center gap-4 mt-8 lg:mt-0"
							>
								<svg
									width="25"
									height="24"
									viewBox="0 0 25 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M23.06 12.25C23.06 11.47 22.99 10.72 22.86 10H12.5V14.26H18.42C18.16 15.63 17.38 16.79 16.21 17.57V20.34H19.78C21.86 18.42 23.06 15.6 23.06 12.25Z"
										fill="#0084FF"
									/>
									<path
										d="M12.4997 22.9996C15.4697 22.9996 17.9597 22.0196 19.7797 20.3396L16.2097 17.5696C15.2297 18.2296 13.9797 18.6296 12.4997 18.6296C9.63969 18.6296 7.20969 16.6996 6.33969 14.0996H2.67969V16.9396C4.48969 20.5296 8.19969 22.9996 12.4997 22.9996Z"
										fill="#34A853"
									/>
									<path
										d="M6.34 14.0903C6.12 13.4303 5.99 12.7303 5.99 12.0003C5.99 11.2703 6.12 10.5703 6.34 9.91031V7.07031H2.68C1.93 8.55031 1.5 10.2203 1.5 12.0003C1.5 13.7803 1.93 15.4503 2.68 16.9303L5.53 14.7103L6.34 14.0903Z"
										fill="#FBBC05"
									/>
									<path
										d="M12.4997 5.38C14.1197 5.38 15.5597 5.94 16.7097 7.02L19.8597 3.87C17.9497 2.09 15.4697 1 12.4997 1C8.19969 1 4.48969 3.47 2.67969 7.07L6.33969 9.91C7.20969 7.31 9.63969 5.38 12.4997 5.38Z"
										fill="#EA4335"
									/>
								</svg>

								Entrar com Google
							</Button>

							<form @submit.prevent="submitLogin" class="space-y-6 flex-1">
								<Input
									label="Email"
									placeholder="Insira o e-mail cadastrado."
									class="px-4"
									type="email"
									labelClass="font-bold"
									v-model="loginData.email"
								/>

								<Button
									:loading="loading.login"
									:disabled="!isValidForm"
									variant="black"
									class="text-base"
									type="submit"
								>
									Entrar
								</Button>
							</form>

							<p class="text-zinc-900/80 text-base">
								Está com dificuldades para acessar sua conta?
								<a href="https://www.instagram.com/streamersclubbr" class="text-[#6F9900] font-bold"
									>Entrar em contato conosco</a
								>.
							</p>
						</div>

						<div class="flex-1 my-auto" v-if="isEmailSent">
							<h1 class="text-2xl font-bold text-zinc-900">
								Te enviaremos um e-mail com o link de login. <br />
							</h1>
							<p class="text-zinc-900/80 text-base mt-4">
								Verifique sua caixa de entrada para acessar o link de login.
							</p>

							<Button @click="isEmailSent = false" variant="black" class="text-base mt-8">
								Enviar outro e-mail
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { toast } from 'vue-sonner'

const loginData = ref({
	email: '',
})
const loading = ref({
	login: false,
})

const route = useRoute()
const { $api } = useNuxtApp()
const config = useRuntimeConfig()
const isEmailSent = ref(false)
const cartId = useCookie('cartId')

const googleRedirectUrl = computed(() => {
	return `${config.public.baseUrl}/api/auth/google${
		route.query.redirectTo ? `?redirectTo=${route.query.redirectTo}` : ''
	}`
})

async function submitLogin() {
	if (loading.value.login) {
		return
	}

	loading.value.login = true

	try {
		await $api<any>('/auth/magic-link', {
			method: 'POST',
			body: {
				...loginData.value,
			},
			...(route.query.redirectTo ? { params: { redirectTo: route.query.redirectTo } } : {}),
		})

		loginData.value.email = ''

		toast.success('E-mail enviado com sucesso.', {
			description: 'Verifique sua caixa de entrada para acessar o link de login.',
		})

		//isEmailSent.value = true;
	} catch (error) {
		console.error(error)
		toast.error('Erro ao enviar e-mail.', {
			description: 'Verifique se o e-mail está correto ou entre em contato com o suporte.',
		})
	}

	loading.value.login = false
}

const isValidForm = computed(() => {
	return !!loginData.value.email
})

onMounted(() => {
	const email = route.query.email as string
	if (email && email.length > 0) {
		loginData.value.email = email
	}
})

useSeoMeta({
	title: 'Login',
	description: 'Faça login no Streamers Club.',
})

definePageMeta({
	middleware: ['authorize-guest'],
})
</script>
